<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.incompleteReport')">
      <form v-on:submit.prevent="getIncompleteReport">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            {{ $t('message.machineId') }} :
            <br>
            <multi-list-select
              :list="machineData"
              option-value="machineId"
              option-text="name"
              :selected-items="selectedMachine"
              placeholder="All"
              @select="onSelectMachine"
            ></multi-list-select>
          </div>
          <div class="col-sm-6 col-md-2">
            {{ $t('message.deviceId') }} :
            <br>
            <b-form-input v-model="searchParams.deviceId"/>
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t('message.selectMonth') }} :
            <br>
            <vue-monthly-picker
              v-model="selectedMonth"
              :monthLabels="monthName"
              dateFormat="MM/YYYY"
            ></vue-monthly-picker>
          </div>
          <div class="col-sm-6 col-md-3">
            <br>
            <b-btn variant="primary" type="submit">
              <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
            </b-btn>&nbsp;&nbsp;&nbsp;
            <!-- <b-btn variant="danger" @click="newReport()">
              <i class="fa fa-plus-square"></i>&nbsp;{{ $t('message.create') }}
            </b-btn> -->
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align: center">
            <tr>
              <th>{{ $t('message.machineIdFarm') }}</th>
              <th>{{ $t('message.deviceId') }}</th>
              <th>{{ $t('message.machine') }}</th>
              <th>{{ $t('message.receiveChannel') }}</th>
              <th>{{ $t('message.time') }}</th>
              <th>{{ $t('message.problemDetail') }}</th>
              <th>{{ $t('message.status') }}</th>
              <th>{{ $t('message.problemSolution') }}</th>
              <th>{{ $t('message.informant') }}</th>
              <th>{{ $t('message.createBy') }}</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody style="text-align: center">
            <tr v-for="item in rowData">
              <td>{{item.machineId}}</td>
              <td>{{item.deviceId}}</td>
              <td>{{item.machineDetails}}</td>
              <td>{{item.channel}}</td>
              <td>{{item.timestamp | moment("YYYY-MM-DD HH:mm")}}</td>
              <td>{{item.details}}</td>
              <template v-if="item.status === 'SUCCESS'">
                <td style="text-align: center">
                  <span class="badge badge-success" style="font-size: 100%">{{ $t('message.solve') }}</span>
                </td>
              </template>
              <template v-else-if="item.status === 'PROCESSING'">
                <td style="text-align: center">
                  <span class="badge badge-warning" style="font-size: 100%">{{ $t('message.processing') }}</span>
                </td>
              </template>
              <template v-else-if="item.status === 'RECEIVED'">
                <td style="text-align: center">
                  <span class="badge badge-primary" style="font-size: 100%">{{ $t('message.received') }}</span>
                </td>
              </template>
              <td>{{item.solution}}</td>
              <td>{{item.informant}}</td>
              <td>{{item.createBy}}</td>
              <td>
                <b-btn variant="warning" @click="editReport(item)" size="sm">{{ $t('message.editOrUpdate') }}</b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-modal
      ref="editModal"
      @ok="updateReport()"
      @cancel="getIncompleteReport()"
      ok-title="บันทึก"
      cancel-title="ยกเลิก"
      title="แก้ไข/อัพเดท"
    >
      <table class="table-modal">
        <tr>
          <td>{{ $t('message.machineIdFarm') }}</td>
          <td>{{modalData.machineId}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.deviceId') }}</td>
          <td>
            <b-form-input type="number" v-model="modalData.deviceId"/>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.problemDetail') }}</td>
          <td>
            <b-form-textarea v-model="modalData.details"/>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.problemSolution') }}</td>
          <td>
            <b-form-textarea v-model="modalData.solution"/>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.status') }}</td>
          <td>
            <b-form-select v-model="modalData.status" :options="status"/>
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      ref="createModal"
      @ok="createReport()"
      @cancel="getIncompleteReport()"
      :ok-title="$t('message.create')"
      :cancel-title="$t('message.cancel')"
      :title="$t('message.createReport')"
      size="lg"
    >
      <table class="table-modal">
        <tr>
          <td>{{ $t('message.machineIdFarm') }}</td>
          <td>
            <model-list-select
              :list="machineData"
              v-model="newReportData.machineId"
              option-value="machineId"
              option-text="name"
              :placeholder="$t('message.machineIdFarm')"
            ></model-list-select>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.deviceId') }}</td>
          <td>
            <b-form-input
              type="number"
              :placeholder="$t('message.deviceId')"
              v-model="newReportData.deviceId"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.receiveChannel') }}</td>
          <td>
            <b-form-input
              type="text"
              placeholder="Ex. Line@ Call FB"
              v-model="newReportData.channel"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.time') }}</td>
          <td>
            <date-picker
              type="datetime"
              v-model="newReportData.timestamp"
              lang="en"
              format="yyyy-MM-dd HH:mm:ss"
            ></date-picker>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.problemDetail') }}</td>
          <td>
            <b-form-textarea type="text" v-model="newReportData.details"></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.problemSolution') }}</td>
          <td>
            <b-form-textarea type="text" v-model="newReportData.solution"></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.informant') }}</td>
          <td>
            <b-form-textarea
              type="text"
              placeholder="PHONE NO./FB/LINE"
              v-model="newReportData.informant"
            ></b-form-textarea>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>


<script>
import moment from "moment";
import webServices from "../../script";
import VueMonthlyPicker from "vue-monthly-picker";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ModelListSelect,
  MultiListSelect
} from "../../custom_modules/search-select";
export default {
  name: "Reports",
  components: {
    MultiListSelect,
    ModelListSelect,
    DatePicker,
    VueMonthlyPicker
  },
  data() {
    return {
      rowData: [],
      modalData: {},
      machineData: [],
      searchParams: {},
      newReportData: {},
      selectedMachine: [],
      status: [
        { value: "SUCCESS", text: "แก้ไขแล้ว" },
        { value: "PROCESSING", text: "กำลังดำเนินการ" },
        { value: "RECEIVED", text: "ได้รับแจ้งปัญหาแล้ว" }
      ],
      selectedMonth: moment(new Date()),
      monthName: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    };
  },
  methods: {
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.$Progress.finish();
          this.machineData = res.data;
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchParams.machineId = this.selectedMachine.map(
        data => data.machineId
      );
    },
    getIncompleteReport() {
      this.searchParams.month = new Date(this.selectedMonth)
      this.$Progress.start();
      webServices
        .getIncompleteReport(this.searchParams)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            this.rowData = res.data.report;
          } else {
            this.$Progress.fail();
            console.log(err);
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
        });
    },
    editReport(data) {
      this.modalData = data;
      this.$refs.editModal.show();
    },
    newReport() {
      this.$refs.createModal.show();
    },
    updateReport() {
      delete this.modalData.timestamp;
      delete this.modalData.createdAt;
      delete this.modalData.updatedAt;
      webServices
        .updateIncompleteReport(this.modalData)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.modalData = {};
          } else {
            this.$Progress.fail();
            console.log(err);
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
          this.getIncompleteReport();
        })
        .catch(err => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
          this.getIncompleteReport();
        });
    },
    createReport() {
      this.newReportData.timestamp = moment(
        this.newReportData.timestamp
      ).format("YYYY-MM-DD HH:mm:ss");
      webServices
        .createIncompleteReport(this.newReportData)
        .then(res => {
          console.log(res.data);
          if (res.data.success) {
            this.$Progress.finish();
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.modalData = {};
          } else {
            this.$Progress.fail();
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
          this.newReportData = {};
          this.getIncompleteReport();
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
          this.getIncompleteReport();
        });
    }
  },
  mounted() {
    this.getMachine();
    this.getIncompleteReport();
  }
};
</script>